<template>
	<div>
		<a-modal title="生成激活码" :width="400" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定生成
				</a-button>
			</template>
			<div>
				<a-form-model
				   ref="ruleForm"
					:model="form"
					:label-col="{ span: 6 }" 
					:wrapper-col="{ span: 18}">	
					<a-form-model-item label="有效天数" required>
						 <a-input-number style="width: 200px;" :formatter="value => `${value}天`" v-model="form.effective" :precision="0" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="作用天数" required help="用于让商铺延长多少天">
						 <a-input-number style="width: 200px;" :formatter="value => `${value}天`" v-model="form.valid_days" :precision="0" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="价格" required help="激活码的价格">
						 <a-input-number style="width: 200px;" :formatter="value => `¥${value}`" v-model="form.price" :precision="2" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="生成几个" required>
						 <a-input-number style="width: 200px;" :formatter="value => `${value}个`" v-model="form.add_number" :precision="0" :min="0" :max="50" />
					</a-form-model-item>
				</a-form-model>
				
			</div>
		</a-modal>
	</div>
</template>

<script>
	// import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		// mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				confirmLoading:false,
				addvisible:false,
				app_id:0,
				form:{
					effective:'',
					valid_days:'',
					price:'',
					add_number:'',
				}
			}
		},
		created() {
			
		},
		methods: {
			

			
	
			
			chooseIt(record){
				this.app_id = record.app_id;
				this.visible=true;
			},
			
			handleAddCancel(){
				this.visible=false;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
		
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('api/activation_code/addActivationCode',this.form).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
</style>
