<template>
    <div>
        <div class="bg-w pd20" style="min-height: 800px;">

            <div class="">
                <div class=" flex space">
                    <div>
                        <a-button type="primary" icon="plus" @click="addAct()">生成激活码</a-button>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-search-box mt20">
                    <a-form layout="inline">
                        <a-form-item label="使用状态">
                            <a-select v-model="search.status" style="width: 200px" placeholder="请选择使用状态">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option :value="2">已使用</a-select-option>
                                <a-select-option :value="1">待使用</a-select-option>
                                <a-select-option :value="3">过期</a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item label="创建时间">
                            <a-date-picker v-model="search.add_time_start" :format="dateFormat" @change="onaddtime" placeholder="请选择创建时间" />
                        </a-form-item>

                        <a-form-item label="结束时间">
                            <a-date-picker v-model="search.add_time_end" :format="dateFormat" @change="onexpiretime" placeholder="请选择创建时间" />
                        </a-form-item>

                        <a-form-item>
                            <a-button @click="searchAct" type="primary">查询</a-button>
                            <a-button @click="searchqx" class="ml10">取消</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>

            <div class="mt30">
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
                        <div class="flex center text-center" slot="status" slot-scope="status">
                            <div v-if="status==1" class="cl-red">未使用</div>
                            <div v-if="status==2" class="cl-green">已使用</div>
                            <div v-if="status==3" class="cl-wrong">过期</div>
                        </div>
                        <template slot="action" slot-scope="record">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
                                    <span class="more-act">
                                        <i class="iconfont iconmore_gray"></i>
                                    </span>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="del(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">删除激活码</span>
                                            </a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="addActivationCodeVisible">
                <add-code :visible="addActivationCodeVisible" @cancel="cancelAct" @ok="okAct"></add-code>
            </div>
        </div>
    </div>
</template>

<script>
import addCode from './components/sms/add-code.vue';
import moment from 'moment';
export default {
    components: {
        addCode,
    },
    data() {
        return {
            loading: false,
            dateFormat: 'YYYY-MM-DD',
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            addActivationCodeVisible: false,
            search: {
                status: '',
                add_time_start: '',
                add_time_end: '',
            },
            columns: [
                { title: 'ID', dataIndex: 'id', align: 'center', ellipsis: true, width: 80 },
                { title: '激活码', dataIndex: 'activation_code', align: 'center', width: 280 },
                { title: '作用天数', dataIndex: 'valid_days', align: 'center' },
                { title: '价格', dataIndex: 'price', align: 'center' },
                { title: '状态', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
                { title: '创建时间', dataIndex: 'inTime', align: 'center', ellipsis: true },
                { title: '过期时间', dataIndex: 'expire_time', align: 'center', ellipsis: true },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            datas: [],
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        moment,
        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/activation_code/listActivationCode', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                ...this.search
            }).then(res => {
                this.pagination.total = res.data.total;
                this.datas = res.data.data;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
            })
        },
        searchqx() {
            this.search = {
                status: '',
                add_time: '',
                expire_time: '',
            }
            this.searchAct()
        },
        searchAct() {
            this.pagination.pageSize = 10
            this.pagination.current = 1
            this.getLists()
        },
        addAct() {
            this.addActivationCodeVisible = true;
        },
        cancelAct() {
            this.addActivationCodeVisible = false;
        },
        okAct() {
            this.addActivationCodeVisible = false;
            this.getLists();
        },
        onaddtime(date, dateString) {
            this.search.add_time_start = dateString
        },

        onexpiretime(date, dateString) {
            this.search.add_time_end = dateString
        },
        del(record) {
            this.$confirm({
                title: '确认删除这个激活码吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('api/activation_code/delActivationCode', {
                            id: record.id,
                        }).then(res => {
                            this.$message.success('删除成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
    }
}
</script>

<style>
.dynamic-case-cover-img {
    width: 80px;
    height: 80px;
}
</style>